import { unref as _unref, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "modal modal-prizes",
  role: "dialog"
}
const _hoisted_2 = { class: "modal-main-headline" }
const _hoisted_3 = { class: "m18 d18" }

import { useI18next } from '@composables/i18next';
    
    import BaseModal from './BaseModal';

    const __default__ = {
        name: 'PrizesModal',

        extends: BaseModal,

        data () {
            return {
                modalName: 'prizes',
            };
        },
    };


export default /*@__PURE__*/Object.assign(__default__, {
  setup(__props) {

    const { vT } = useI18next(['prizes', 'global']);

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay",
    onClick: _cache[1] || (_cache[1] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createElementVNode("h2", _hoisted_2, null, 512), [
        [_unref(vT), 'prizes:headline_copy']
      ]),
      _withDirectives(_createElementVNode("div", null, null, 512), [
        [_unref(vT), 'prizes:body_copy']
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_3, null, 512), [
        [_unref(vT), 'global:kuerig_disclaimer']
      ]),
      _createElementVNode("button", {
        class: "modal-close-x",
        "data-e2e": "error-modal-close",
        title: "Close",
        onClick: _cache[0] || (_cache[0] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, _cache[2] || (_cache[2] = [
        _createElementVNode("i", { class: "fas fa-times" }, null, -1)
      ]))
    ])
  ]))
}
}

})