import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "modal",
  role: "dialog"
}
const _hoisted_2 = { class: "modal-main-headline" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay",
    onClick: _cache[1] || (_cache[1] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", _hoisted_2, _toDisplayString($props.headline), 1),
      _createElementVNode("p", null, _toDisplayString($props.body), 1),
      _createElementVNode("button", {
        class: "modal-close-x",
        "data-e2e": "error-modal-close",
        title: "Close",
        onClick: _cache[0] || (_cache[0] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, _cache[2] || (_cache[2] = [
        _createElementVNode("i", { class: "fas fa-times" }, null, -1)
      ]))
    ])
  ]))
}