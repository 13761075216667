
import store from '../store/index';

// Asynchronous component definitions for lazy-loading routes.
// https://v3.router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments
const
    HubPage = async () => import(
        /* webpackChunkName: 'hub' */
        '../views/HubPage.vue'
    ),
    AddressPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/AddressPage.vue'
    ),
    LandingPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/LandingPage.vue'
    );

export default [
    { path: '/', redirect: (to) => {
        // Used to send server-side errors to the '/error' page.
        if (store.state.app.defaultRoute) {
            return store.state.app.defaultRoute;
        }
        // Default routes for logged-in users.
        else if (
            store.getters['profile/loggedIn'] &&
            !store.getters['profile/isSessionExpired']
        ) {
            console.log('logged in');

            if (store.state.profile.address1) {
                if (store.getters['award/isLimited']) {
                    return '/hub';
                }
                else {
                    return '/game';
                }
            }
            else {
                return '/address';
            }
        }

        // Default route for a user we know nothing about.
        return '/landing';
    } },
    { path: '/landing', component: LandingPage, meta: { public: true } },
    { path: '/address', component: AddressPage },
    { path: '/hub', component: HubPage },
    { path: '/logout', redirect: (to, from, next) => {
        store.dispatch('profile/logOut');
        return '/';
    } },
];
