import { normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_t = _resolveDirective("t")
  const _directive_track = _resolveDirective("track")

  return _withDirectives((_openBlock(), _createElementBlock("a", {
    href: $options.generatedUrl,
    class: _normalizeClass(`button button-${$props.type}`)
  }, null, 10, _hoisted_1)), [
    [_directive_t, `buttons:${$props.type}`],
    [_directive_track, ['click', { category: 'SSOButton', label: $props.type }]]
  ])
}