
    import SSOButton from '@components/SSOButton.vue';
    import BaseModal from './BaseModal';

    export default {
        name: 'SessionExpirationModal',

        components: {
            SSOButton,
        },

        extends: BaseModal,

        data () {
            return {
                modalName: 'session-expiration',
            };
        },

        methods: {
            redirect () {
                window.location = this.$refs.sso.generatedUrl;
            },
        },
    };
