import { unref as _unref, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-error" }

import { useI18next } from '@composables/i18next';
    
    import BasePage from './BasePage';

    const __default__ = {
        name: 'ErrorPage',

        extends: BasePage,

        data () {
            return {
                pageName: 'error',
            };
        },
    };


export default /*@__PURE__*/Object.assign(__default__, {
  setup(__props) {

    const { vT } = useI18next(['error']);

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _withDirectives(_createElementVNode("h2", null, null, 512), [
      [_unref(vT), 'error:headline_copy']
    ]),
    _withDirectives(_createElementVNode("div", null, null, 512), [
      [_unref(vT), 'error:body_copy']
    ])
  ]))
}
}

})