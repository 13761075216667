
    import { useI18next } from '@composables/i18next';
    import { openPopup } from '../popup';

    export default {
        name: 'NavList',

        setup () {
            const { t, vT } = useI18next(['links']);
            return { t, vT };
        },

        methods: {
            openPopup,
        },
    };
