import { unref as _unref, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  class: "modal modal-game",
  role: "dialog"
}

import { useI18next } from '@composables/i18next';
    
    import BaseModal from './BaseModal';

    const __default__ = {
        name: 'GameOverModal',

        extends: BaseModal,

        props: {
            found: {
                type: Number,
                default: 0,
            },
        },

        data () {
            return {
                modalName: 'gameOver',
            };
        },
    };


export default /*@__PURE__*/Object.assign(__default__, {
  setup(__props) {

    const { vT } = useI18next(['game']);

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay",
    onClick: _cache[2] || (_cache[2] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createElementVNode("h2", null, null, 512), [
        [_unref(vT), 'modal.headline_copy']
      ]),
      _withDirectives(_createElementVNode("div", null, null, 512), [
        [_unref(vT), { key: 'modal.body_copy', found: __props.found }]
      ]),
      (_ctx.$store.getters['award/isLimited'])
        ? _withDirectives((_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "button",
            onClick: _cache[0] || (_cache[0] = $event => (_ctx.closeModal()))
          }, null, 512)), [
            [_unref(vT), 'modal.next']
          ])
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _withDirectives(_createElementVNode("div", null, null, 512), [
              [_unref(vT), 'modal.body_copy_2']
            ]),
            _withDirectives(_createElementVNode("button", {
              class: "button",
              onClick: _cache[1] || (_cache[1] = $event => (_ctx.closeModal()))
            }, null, 512), [
              [_unref(vT), 'modal.cta']
            ])
          ], 64))
    ])
  ]))
}
}

})