
import store from '../store/index';

// Asynchronous component definitions for lazy-loading routes.
// https://v3.router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments
const
    ResultsPage = async () => import(
        /* webpackChunkName: 'results' */
        '../views/ResultsPage.vue'
    ),
    GamePage = async () => import(
        /* webpackChunkName: 'game' */
        '../views/GamePage.vue'
    ),
    InkyPage = async () => import(
        /* webpackChunkName: 'results' */
        '../views/InkyPage.vue'
    ),
    JankyPage = async () => import(
        /* webpackChunkName: 'results' */
        '../views/JankyPage.vue'
    );

const getResults = ({
    path,
    next,
    isDefault = false,
}) => {
    const smartNext = (pathToGoTo) => {
        if (!isDefault) {
            // if pathToGoTo == path, then next() allows that pathToGoTo to render rather than redirect
            // (aka, if you don't do this, it'll cause an infinite render loop)
            if (pathToGoTo === path) {
                return next();
            }
            else {
                return next(pathToGoTo);
            }
        }
        return next(pathToGoTo);
    };

    if (store.state.award.canSeeResults) {
        const { prizeWon } = store.state.award;
        if (prizeWon) {
            return smartNext('/inky');
        }
        return smartNext('/janky');
    }
    return smartNext('/'); // there's an isLimited check in /'s redirect that will take care of this case
};

export default [
    { path: '/game',  component: GamePage },
    { path: '/results',  component: ResultsPage },
    // explaination for later:
    // this works by repurposing commonly shaped code (in a visual/physical sense)
    // that essentially works the same, but one (redirect) expects string returns
    // whereas /inky and /janky expect next() cascade throughs
    { path: '/game-results', redirect: () => getResults({
        path: '/game-results',
        next: (str) => str,
        isDefault: true,
    }) },
    { path: '/inky',  component: InkyPage, beforeEnter: (to, from, next) => getResults({
        path: '/inky',
        next,
    }) },
    { path: '/janky', component: JankyPage, beforeEnter: (to, from, next) => getResults({
        path: '/janky',
        next,
    }) },
];
