import { unref as _unref, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "receipt-upload" }
const _hoisted_2 = { class: "margin-bottom-32" }
const _hoisted_3 = ["href"]
const _hoisted_4 = { class: "m18 d18 margin-bottom-32" }
const _hoisted_5 = { class: "m18 d18" }

import { useI18next } from '@composables/i18next';
    
    import { mapState } from 'vuex';
    const __default__ = {
        name: 'ReceiptUpload',

        computed: {
            ...mapState(['app']),
            cta_href () {
                return this.app.uploadURL;
            },
        },
    };


export default /*@__PURE__*/Object.assign(__default__, {
  setup(__props) {

    const { t, vT } = useI18next(['receipt', 'buttons']);

return (_ctx, _cache) => {
  const _directive_track = _resolveDirective("track")

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _withDirectives(_createElementVNode("h2", _hoisted_2, null, 512), [
      [_unref(vT), 'receipt:headline_copy']
    ]),
    _withDirectives(_createElementVNode("div", null, null, 512), [
      [_unref(vT), 'receipt:body_copy']
    ]),
    _withDirectives((_openBlock(), _createElementBlock("a", {
      class: "button alt-outline m38 d38 margin-bottom-32",
      href: _ctx.cta_href
    }, [
      _createTextVNode(_toDisplayString(_unref(t)('buttons:upload')), 1)
    ], 8, _hoisted_3)), [
      [_directive_track, ['click', { category: 'Dahe button', label: 'Upload Receipt' }]]
    ]),
    _withDirectives(_createElementVNode("p", _hoisted_4, null, 512), [
      [_unref(vT), 'global:kuerig_disclaimer']
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_5, null, 512), [
      [_unref(vT), { key: 'receipt:body_copy_2', tb2URL: _ctx.$store.state.app.tb2URL }]
    ])
  ]))
}
}

})