
import axios from 'axios';
import { reactive } from 'vue';
import api from '../../api';
import { setRecaptchaV3Challenge } from '../../recaptcha';

const getDefaultState = () => reactive({
    // todo [coreifyB]: there's no reason awards should ever be null if it's the source of truth for isLimited statuses out of core
    // JK, ^^^^ messes a LOT of logic up.... it's very side effecty which is wild and kinda not good lol
    awards: null,
    prizeWon: null,
    uuid: '', // for consistency with falsey state of updatePrizeWon
    canSeeResults: false,
    isRefreshLimited: false,
});

const state = getDefaultState();

const getters = {
    isLimited: (state) => state.awards?.isLimited || state.isRefreshLimited,
};

const mutations = {
    updateAwards (state, awards) {
        state.awards = awards;
    },
    // todo [corifyA]: make uuid a plain state property for ease of use/easy intuition
    updatePrizeWon (state, prizeWon) {
        state.prizeWon = prizeWon;
        state.uuid = (prizeWon?.id || '').replaceAll('-', '').toLowerCase();
    },
    unlockFlow (state) {
        state.canSeeResults = true;
    },
    lockFlow (state) {
        state.canSeeResults = false;
    },
    updateLimited (state, limitedStatus) {
        state.isRefreshLimited = limitedStatus;
    },
};

const actions = {
    async getAwards ({ dispatch }) {
        return dispatch('makeCall', {
            type: 'get',
            endpoint: 'awards',
        });
    },

    async awardEvent ({ dispatch }, { event }) {
        const data = {};
        await setRecaptchaV3Challenge({ data, action: 'award' });
        return dispatch('makeCall', {
            endpoint: `awards/events/${encodeURIComponent(event)}:award`,
            data,
        });
    },

    async play ({ dispatch, state, commit }, testData) {
        // if isProd || testData is not declared (aka play call and sso works on local somehow)
        // awardEvent only works with a valid and active sso token
        await dispatch('awardEvent', { event: 'generateToken' });
        const data = {};
        await setRecaptchaV3Challenge({ data, action: 'play' });
        await dispatch('makeCall', {
            endpoint: 'awards/:play',
            data,
        });
        return state.prizeWon;
    },

    async makeCall ({ commit }, {
        type = 'post',
        endpoint,
        data,
    }) {
        let response;

        try {
            response = await axios[type](`${api.base}/${endpoint}`, data);
        }
        catch (err) {
            if (err.response?.status === 429) {
                // User was only limited, carry on
                ({ response } = err);
            }
            else {
                console.error(
                    `error making ${endpoint} call`,
                    err.message,
                    err,
                );

                throw err;
            }
        }

        if (response.data?.awards !== undefined) {
            commit('updateAwards', response.data.awards);
        }
        if (response.data?.prizeWon !== undefined) {
            commit('updatePrizeWon', response.data.prizeWon);
        }

        return response;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
