export const waitMs = (msToWait) => new Promise(resolve => setTimeout(resolve, msToWait));

const rAF = () => new Promise(resolve => requestAnimationFrame(resolve));

export const waitMsRAF = async (msToWait) =>  {
    const startTime = new Date().getTime();
    const delta = () => new Date().getTime() - startTime;
    // :O jk, eslint is now my bestie: code == ^elevated^
    while (delta() < msToWait)  await rAF();
};
