import { resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "menu-list" }
const _hoisted_2 = ["href", "title"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_t = _resolveDirective("t")

  return (_openBlock(), _createElementBlock("nav", null, [
    _createElementVNode("ul", _hoisted_1, [
      _withDirectives(_createElementVNode("li", null, null, 512), [
        [_directive_t, { key: 'links:rules_rs', tb2URL: _ctx.$store.state.app.tb2URL }]
      ]),
      _withDirectives(_createElementVNode("li", null, null, 512), [
        [_directive_t, 'links:privacy']
      ]),
      _createElementVNode("li", null, [
        _withDirectives(_createElementVNode("a", {
          href: "/faq",
          onClick: _cache[0] || (_cache[0] = _withModifiers((...args) => ($options.openPopup && $options.openPopup(...args)), ["prevent"]))
        }, null, 512), [
          [_directive_t, 'links:faq_copy']
        ])
      ]),
      _withDirectives(_createElementVNode("li", null, null, 512), [
        [_directive_t, 'links:terms']
      ]),
      _createElementVNode("li", null, [
        _withDirectives(_createElementVNode("a", {
          href: _ctx.$store.state.app.uploadURL,
          target: "_blank",
          title: `${_ctx.$t('links:dahe')} ${_ctx.$t('links:new_window')}`
        }, null, 8, _hoisted_2), [
          [_directive_t, 'links:dahe']
        ])
      ])
    ])
  ]))
}