import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "modal",
  role: "dialog"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SSOButton = _resolveComponent("SSOButton")

  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay",
    onClick: _cache[0] || (_cache[0] = _withModifiers($event => ($options.redirect()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _cache[1] || (_cache[1] = _createElementVNode("h2", null, " Your Session Has Expired ", -1)),
      _cache[2] || (_cache[2] = _createElementVNode("p", null, "Please log in again.", -1)),
      _createVNode(_component_SSOButton, {
        ref: "sso",
        "data-e2e": "session-expiration-modal-close",
        type: 'login'
      }, null, 512)
    ])
  ]))
}