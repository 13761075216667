import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "footer" }
const _hoisted_2 = { class: "disclaimer" }

import { useI18next } from '@composables/i18next';
    
    import { mapState, mapGetters, mapActions } from 'vuex';
    import NavList from '@components/NavList';
    import { openPopup } from '../popup';

    const __default__ = {
        name: 'TheFooter',

        components: {
            NavList,
        },

        computed: {
            ...mapState([
                'app',
                'profile',
            ]),
            ...mapGetters({
                loggedIn: 'profile/loggedIn',
            }),
        },

        methods: {
            openPopup,

            ...mapActions({
                logOut: 'profile/logOut',
            }),
        },
    };



export default /*@__PURE__*/Object.assign(__default__, {
  setup(__props) {

    const { vT } = useI18next(['footer']);

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createVNode(_unref(NavList), { class: "large-only" }),
    _withDirectives(_createElementVNode("p", _hoisted_2, null, 512), [
      [_unref(vT), { key: 'footer:disclaimer', tb2URL: _ctx.$store.state.app.tb2URL }]
    ])
  ]))
}
}

})