import { unref as _unref, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@public/img/landing_win_images.png'


const _hoisted_1 = { class: "prize-section" }
const _hoisted_2 = ["alt"]

import { useI18next } from '@composables/i18next';
    
    const __default__ = {
        name: 'PrizeSection',
    };


export default /*@__PURE__*/Object.assign(__default__, {
  setup(__props) {

    const { vT } = useI18next(['prizes']);

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _withDirectives(_createElementVNode("h2", null, null, 512), [
      [_unref(vT), 'prizes:prize_section.headline_copy']
    ]),
    _createElementVNode("img", {
      class: "landing-win-image",
      src: _imports_0,
      alt: _ctx.$t('prizes:prize_section.image_alt')
    }, null, 8, _hoisted_2)
  ]))
}
}

})