import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = {
  id: "main-section",
  role: "main"
}
const _hoisted_4 = { class: "page-content main-section" }
const _hoisted_5 = {
  key: 1,
  class: "game-warning-overlay"
}
const _hoisted_6 = {
  key: 2,
  class: "game-warning"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TheHeader = _resolveComponent("TheHeader")
  const _component_TheMenu = _resolveComponent("TheMenu")
  const _component_router_view = _resolveComponent("router-view")
  const _component_PrizeSection = _resolveComponent("PrizeSection")
  const _component_TheCarousel = _resolveComponent("TheCarousel")
  const _component_SocialShare = _resolveComponent("SocialShare")
  const _component_ReceiptUpload = _resolveComponent("ReceiptUpload")
  const _component_TheFooter = _resolveComponent("TheFooter")
  const _component_CookieGate = _resolveComponent("CookieGate")
  const _directive_t = _resolveDirective("t")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.ui.pageName != 'faq' && _ctx.ui.pageName != 'results')
        ? (_openBlock(), _createBlock(_component_TheHeader, { key: 0 }))
        : _createCommentVNode("", true),
      (_ctx.ui.pageName != 'faq' && _ctx.ui.pageName != 'results')
        ? (_openBlock(), _createBlock(_component_TheMenu, { key: 1 }))
        : _createCommentVNode("", true),
      _createElementVNode("main", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_router_view, { class: "content" })
        ]),
        (_ctx.ui.pageName === 'landing')
          ? (_openBlock(), _createBlock(_component_PrizeSection, { key: 0 }))
          : _createCommentVNode("", true),
        (_ctx.ui.pageName === 'landing')
          ? (_openBlock(), _createBlock(_component_TheCarousel, { key: 1 }))
          : _createCommentVNode("", true),
        ($data.HasSocialShare.includes(_ctx.ui.pageName))
          ? (_openBlock(), _createBlock(_component_SocialShare, { key: 2 }))
          : _createCommentVNode("", true),
        ($data.HasReceiptUpload.includes(_ctx.ui.pageName))
          ? (_openBlock(), _createBlock(_component_ReceiptUpload, { key: 3 }))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.ui.pageName != 'faq' && _ctx.ui.pageName != 'results')
      ? (_openBlock(), _createBlock(_component_TheFooter, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_CookieGate, {
      "body-copy": $setup.t('cookie_gate:body_copy', { tb2URL: _ctx.$store.state.app.tb2URL }),
      "button-copy": $setup.t('cookie_gate:button_copy'),
      "title-copy": $setup.t('cookie_gate:title')
    }, null, 8, ["body-copy", "button-copy", "title-copy"]),
    (_ctx.ui.pageName === 'game')
      ? (_openBlock(), _createElementBlock("div", _hoisted_5))
      : _createCommentVNode("", true),
    (_ctx.ui.pageName === 'game')
      ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_6, null, 512)), [
          [_directive_t, 'onboarding:warning']
        ])
      : _createCommentVNode("", true)
  ]))
}