import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "header",
  role: "banner",
  "data-e2e": "global-header"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("h1", {
      ref: "h1",
      class: "main-header"
    }, _toDisplayString($setup.t('global:title')), 513)
  ]))
}