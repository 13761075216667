
    export default {
        props: {
            type: {
                type: String,
                required: true,
            },
        },

        computed: {
            affiliateAppend () {
                const { affiliateId } = this.$store.state.app;
                return affiliateId ? `&affiliateId=${affiliateId}` : '';
            },
            generatedUrl () {
                return `/oauth/redirect?target=${this.type}${this.affiliateAppend}`;
            },
        },
    };
