import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "menu" }
const _hoisted_2 = {
  ref: "menu",
  class: "menu-dialog"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_NavList = _resolveComponent("NavList")

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("button", {
      class: "menu-button-open small-only",
      title: "Open Menu",
      onClick: _cache[0] || (_cache[0] = _withModifiers($event => ($options.openMenu()), ["prevent"]))
    }, _cache[2] || (_cache[2] = [
      _createElementVNode("i", { class: "fas fa-bars" }, null, -1)
    ])),
    _createElementVNode("dialog", _hoisted_2, [
      _createVNode(_component_NavList),
      _createElementVNode("button", {
        class: "menu-button-close",
        title: "Close Menu",
        onClick: _cache[1] || (_cache[1] = _withModifiers($event => ($options.closeMenu()), ["prevent"]))
      }, _cache[3] || (_cache[3] = [
        _createElementVNode("i", { class: "fas fa-times" }, null, -1)
      ]))
    ], 512)
  ]))
}