import { unref as _unref, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "social-share" }
const _hoisted_2 = { class: "m36 d36 margin-bottom-32" }
const _hoisted_3 = ["title", "aria-label"]
const _hoisted_4 = ["title", "aria-label"]

import { useI18next } from '@composables/i18next';
    
    import '../../vendor/esocial';

    const __default__ = {
        name: 'SocialShare',
    };


export default /*@__PURE__*/Object.assign(__default__, {
  setup(__props) {

    const { vT } = useI18next(['share']);

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _withDirectives(_createElementVNode("h2", _hoisted_2, null, 512), [
      [_unref(vT), 'share:headline']
    ]),
    _createElementVNode("ul", null, [
      _createElementVNode("li", null, [
        _createElementVNode("a", {
          class: "facebook_share",
          href: "#facebook_share",
          "data-e2e": "facebook-share-link",
          title: _ctx.$t('share:fb.link_title'),
          "aria-label": _ctx.$t('share:fb.link_title')
        }, _cache[0] || (_cache[0] = [
          _createElementVNode("svg", {
            alt: "Facebook",
            xmlns: "http://www.w3.org/2000/svg",
            x: "0px",
            y: "0px",
            width: "100",
            height: "100",
            viewBox: "0 0 50 50",
            title: "Facebook"
          }, [
            _createElementVNode("path", {
              fill: "#ffffff",
              d: "M25,3C12.85,3,3,12.85,3,25c0,11.03,8.125,20.137,18.712,21.728V30.831h-5.443v-5.783h5.443v-3.848 c0-6.371,3.104-9.168,8.399-9.168c2.536,0,3.877,0.188,4.512,0.274v5.048h-3.612c-2.248,0-3.033,2.131-3.033,4.533v3.161h6.588 l-0.894,5.783h-5.694v15.944C38.716,45.318,47,36.137,47,25C47,12.85,37.15,3,25,3z"
            })
          ], -1)
        ]), 8, _hoisted_3)
      ]),
      _createElementVNode("li", null, [
        _createElementVNode("a", {
          class: "twitter_share",
          href: "#twitter_share",
          "data-e2e": "twitter-share-link",
          title: _ctx.$t('share:twitter.link_title'),
          "aria-label": _ctx.$t('share:twitter.link_title')
        }, _cache[1] || (_cache[1] = [
          _createElementVNode("svg", {
            alt: "X",
            xmlns: "http://www.w3.org/2000/svg",
            x: "0px",
            y: "0px",
            width: "100",
            height: "100",
            viewBox: "0 0 50 50",
            title: "X"
          }, [
            _createElementVNode("path", {
              fill: "#ffffff",
              d: "M 5.9199219 6 L 20.582031 27.375 L 6.2304688 44 L 9.4101562 44 L 21.986328 29.421875 L 31.986328 44 L 44 44 L 28.681641 21.669922 L 42.199219 6 L 39.029297 6 L 27.275391 19.617188 L 17.933594 6 L 5.9199219 6 z M 9.7167969 8 L 16.880859 8 L 40.203125 42 L 33.039062 42 L 9.7167969 8 z"
            })
          ], -1)
        ]), 8, _hoisted_4)
      ])
    ])
  ]))
}
}

})