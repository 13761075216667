
    import { useI18next } from '@composables/i18next';
    import NavList from '@components/NavList';
    import { openPopup } from '../popup';

    export default {
        name: 'TheMenu',

        components: {
            NavList,
        },

        setup () {
            const { t, vT } = useI18next(['links']);
            return { t, vT };
        },

        methods: {
            closeMenu () {
                this.$refs.menu.close();
            },

            openMenu () {
                this.$refs.menu.showModal();
            },

            openPopup,
        },
    };
