import { unref as _unref, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@public/img/icon-card.svg'
import _imports_1 from '@public/img/icon-cake.svg'
import _imports_2 from '@public/img/icon-present.svg'


const _hoisted_1 = { class: "carousel-section" }
const _hoisted_2 = { class: "carousel-slide" }
const _hoisted_3 = { class: "carousel-slide" }
const _hoisted_4 = { class: "carousel-slide" }
const _hoisted_5 = { class: "carousel-slide" }
const _hoisted_6 = ["title"]
const _hoisted_7 = {
  key: 0,
  class: "fas fa-pause-circle"
}
const _hoisted_8 = {
  key: 1,
  class: "fas fa-play-circle"
}

import { useI18next } from '@composables/i18next';
    
    import 'vue3-carousel/dist/carousel.css';
    import { Carousel, Slide, Pagination } from 'vue3-carousel';
    import SSOButton from '@components/SSOButton.vue';

    const __default__ = {
        name: 'TheCarousel',

        components: {
            Carousel,
            Pagination,
            Slide,
            SSOButton,
        },

        data () {
            return {
                autoplay: 3000,
                breakpoints: {
                    1: {
                        itemsToShow: 1.435,
                    },
                    768: {
                        itemsToShow: 3.587,
                    },
                },
            };
        },

        mounted () {
            if (window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
                this.autoplay = false;
            }
        },

        methods: {
            handlePaginationClick (e) {
                if (e.target.classList.contains('carousel__pagination-button')) {
                    this.autoplay = false;
                }
            },

            toggleCarouselAnimation () {
                this.autoplay ? this.autoplay = false : this.autoplay = 3000;
            },
        },
    };


export default /*@__PURE__*/Object.assign(__default__, {
  setup(__props) {

    const { vT } = useI18next(['carousel', 'buttons']);

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _withDirectives(_createElementVNode("h2", null, null, 512), [
      [_unref(vT), 'carousel:headline_copy']
    ]),
    _createVNode(_unref(Carousel), {
      autoplay: _ctx.autoplay,
      breakpoints: _ctx.breakpoints,
      "items-to-show": 2.5,
      "snap-align": _ctx.center,
      "wrap-around": true
    }, {
      addons: _withCtx(() => [
        _createVNode(_unref(Pagination), { onClick: _ctx.handlePaginationClick }, null, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _createVNode(_unref(Slide), { "slide-width": 100 }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _cache[1] || (_cache[1] = _createElementVNode("span", { class: "slide-points-for-purchases-icon" }, [
                _createElementVNode("span", { class: "slide-points-for-purchases-copy1" }, "$1 = "),
                _createElementVNode("span", { class: "slide-points-for-purchases-circle" }, [
                  _createElementVNode("span", { class: "slide-points-for-purchases-copy2" }, "10"),
                  _createElementVNode("br"),
                  _createElementVNode("span", { class: "slide-points-for-purchases-copy3" }, "points")
                ])
              ], -1)),
              _withDirectives(_createElementVNode("div", null, null, 512), [
                [_unref(vT), 'carousel:slide_points_for_purchases']
              ])
            ])
          ]),
          _: 1
        }),
        _createVNode(_unref(Slide), { "slide-width": 100 }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _cache[2] || (_cache[2] = _createElementVNode("span", { class: "slide-first-upload-icon" }, [
                _createElementVNode("span", { class: "slide-first-upload-card" }, [
                  _createElementVNode("img", {
                    alt: "",
                    class: "slide-first-upload-card-icon",
                    height: "71",
                    src: _imports_0,
                    width: "134"
                  }),
                  _createElementVNode("span", { class: "slide-first-upload-five" }, " $5 ")
                ])
              ], -1)),
              _withDirectives(_createElementVNode("div", null, null, 512), [
                [_unref(vT), 'carousel:slide_first_upload']
              ])
            ])
          ]),
          _: 1
        }),
        _createVNode(_unref(Slide), { "slide-width": 100 }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _cache[3] || (_cache[3] = _createElementVNode("img", {
                alt: "",
                class: "slide-birthday-cake",
                height: "156",
                src: _imports_1,
                width: "127"
              }, null, -1)),
              _withDirectives(_createElementVNode("div", null, null, 512), [
                [_unref(vT), 'carousel:slide_birthday']
              ])
            ])
          ]),
          _: 1
        }),
        _createVNode(_unref(Slide), { "slide-width": 100 }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_5, [
              _cache[4] || (_cache[4] = _createElementVNode("img", {
                alt: "",
                class: "slide-extras-present",
                height: "164",
                src: _imports_2,
                width: "154"
              }, null, -1)),
              _withDirectives(_createElementVNode("div", null, null, 512), [
                [_unref(vT), 'carousel:slide_extras']
              ])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["autoplay", "breakpoints", "snap-align"]),
    _createElementVNode("button", {
      class: "carousel-button-play-pause",
      title: _ctx.autoplay ? 'Pause carousel' : 'Play carousel',
      onClick: _cache[0] || (_cache[0] = $event => (_ctx.toggleCarouselAnimation()))
    }, [
      (_ctx.autoplay)
        ? (_openBlock(), _createElementBlock("i", _hoisted_7))
        : (_openBlock(), _createElementBlock("i", _hoisted_8))
    ], 8, _hoisted_6),
    _createVNode(SSOButton, {
      type: 'register',
      class: "carousel-button m30 d30"
    })
  ]))
}
}

})